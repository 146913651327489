import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import SEO from "../components/seo"
import Img from 'gatsby-image'
import { MDXRenderer } from "gatsby-plugin-mdx";

export const postQuery = graphql`
query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
    id
    body
    frontmatter {
      title
      image {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`

const SinglePost = ({ data }) => {
  const post = data.mdx.frontmatter;
  return (
    <Layout>
      <SEO title={post.title} />
      <h1>{post.title}</h1><br />
      <MDXRenderer>{data.mdx.body}</MDXRenderer>
    </Layout>
  );
};
export default SinglePost;